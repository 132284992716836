<template>
    <div id="home-page">
        <section class="ant-layout">
            <!-- <header class="ant-layout-header">
                <Head></Head>
            </header> -->
            <main class="ant-layout-content main-section" style="padding-top: 80px;padding-left: 50px;padding-right: 50px">

                <h1 style="color:white">Our mobile app is coming soon! Please download our mobile app to watch on your Mobile!</h1>

            </main>
            <footer class="ant-layout-footer">
                <Foot></Foot>
            </footer>
        </section>
    </div>
</template>


<script>
import Head from '@/components/common/Head'
import Foot from '@/components/common/Foot'

export default {
    name: 'HomePage',
    head: {
        title: "熊猫侠 - 正在热映",
        meta:[
            {
                name: 'description',
                content: '熊猫侠(www.pandaman.tv), 致力于打造海外华人最大的在线视频平台, 以高清的视频内容，流畅的视频体验.',
            },
            {
                name: 'keywords',
                content: '熊猫侠,视频,视频分享,视频搜索,视频播放,视频社区,海外视频,电影,电视剧,真人秀,美剧,动漫,综艺,华人,影院',
            }
        ]
    },
    data() {
        return {
            items:null,
            options: {},
        };
    },
    components: {
        Head,
        Foot,
    },
    methods: {
    },
    mounted() {
    }

}

</script>
